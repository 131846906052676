<template>
  <div
    class="page-catalog__filter"
    :class="{ 'is-show': isShowFilter }"
  >
    <form
      @submit.prevent="searchGo"
      class="page-catalog__search"
    >
      <input
        class="page-catalog__filter-title"
        :placeholder="$t('catalog.search')"
        v-model="searchItem"
      />
      <img
        src="@/assets/img/icons/search.svg"
        alt=""
      />
    </form>

    <!-- filter menu -->
    <div
      v-if="categoriesAccordion.length"
      class="page-catalog__accordions"
    >
      <div
        class="page-catalog__accordions-body"
        v-for="(category, index) in categoriesAccordion"
        :key="index"
      >
        <h3
          class="page-catalog__accordions-title"
          @click="changeCategory(category.category.id)"
        >
          {{ category.category.label }}
        </h3>
        <div class="page-catalog__accordion">
          <div v-if="category.isOpen">
            <div class="page-catalog__accordion-body">
              <div
                v-for="(filterItem, index) in category.subcategories"
                :key="index"
                class="page-catalog__list-item"
              >
                <div v-if="filterItem.attributes.products.data.length">
                  <input
                    type="checkbox"
                    :id="getUniqId(filterItem)"
                    :checked="isChecked(category, filterItem)"
                    @change="checkFilter(category.type, filterItem)"
                  />
                  <label
                    class="filter-label"
                    :for="getUniqId(filterItem)"
                  >
                    {{ getLabel(filterItem) }}
                    <span v-if="category.type === 'volume'">Л</span>
                    <span v-if="category.type === 'strenght'">%</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="page-catalog__filter-btns">
              <button
                class="catalog-filter-reset"
                @click="resetCategory"
              >
                Очистить все
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Action Buttons -->
    <div
      v-if="categoriesAccordion.length && isShowProductsFilters"
      class="page-catalog__accordions"
    >
      <h6>Фильтр:</h6>
      <div
        class="page-catalog__accordions-body"
        v-for="(filter, index) in filters"
        :key="index"
      >
        <h3
          v-if="filter.data.length"
          @click="filter.isShow = !filter.isShow"
          class="page-catalog__accordions-title"
        >
          {{ $t(filter.label) }}
        </h3>
        <div
          :class="{ 'is-show': filter.isShow }"
          class="page-catalog__accordion page-catalog__accordion-filter"
        >
          <div class="page-catalog__accordion-body">
            <div
              v-for="(filterItem, index) in filter.data"
              :key="index"
              class="page-catalog__list-item"
            >
              <input
                type="checkbox"
                :id="getUniqId(filterItem)"
                :checked="isChecked(filter, filterItem)"
                @change="checkFilter(filter.type, filterItem)"
              />
              <label
                class="filter-label"
                :for="getUniqId(filterItem)"
              >
                {{ getLabel(filterItem) }}
                <span v-if="filter.type === 'volume'">Л</span>
                <span v-if="filter.type === 'strenght'">%</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="page-catalog__filter-btns">
        <button
          class="catalog-filter-reset"
          @click="resetFilters"
        >
          {{ $t("catalog.reset") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { actionTypes } from "@/store/modules/catalog"
import { getProductsByIds } from "@/api/products"
import { getAllCategoriesFiltered } from "@/api/category"
import { actionTypes as actionTypesCategories } from "@/store/modules/categories"

export default {
  name: "DiscountFilters",
  props: {
    isShowFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowProductsFilters: false,
      searchItem: "",
      categoriesAccordion: [],
      filters: [
        {
          id: 1,
          label: "attributes.country",
          data: [],
          type: "countries",
          isShow: false,
        },
        {
          id: 2,
          label: "attributes.sugar",
          data: [],
          type: "sugar",
          isShow: false,
        },
        {
          id: 3,
          label: "attributes.volume",
          data: [],
          type: "volume",
          isShow: false,
        },
        {
          id: 4,
          label: "attributes.grape",
          data: [],
          type: "vinePlace",
          isShow: false,
        },
        {
          id: 5,
          label: "attributes.fortress",
          data: [],
          type: "strenght",
          isShow: false,
        },
        {
          id: 6,
          label: "attributes.brands",
          data: [],
          type: "brand",
          isShow: false,
        },
      ],
      attributes: [
        {
          type: "countries",
          value: "country",
          data: [],
        },
        {
          type: "sugar",
          value: "sugar",
          data: [],
        },
        {
          type: "volume",
          value: "volume",
          data: [],
        },
        {
          type: "strenght",
          value: "strenght",
          data: [],
        },
        {
          type: "brand",
          value: "brand",
          data: [],
        },
        {
          type: "vinePlace",
          value: "vine_place",
          data: [],
        },
      ],
      category: null,
      categories: [],
      categoriesFilters: [],
      productsIds: [],
      productsList: [],
      menu: [],
      menuAttributes: [
        {
          type: "countries",
          value: "country",
          data: [],
        },
        {
          type: "sugar",
          value: "sugar",
          data: [],
        },
        {
          type: "volume",
          value: "volume",
          data: [],
        },
        {
          type: "strenght",
          value: "strenght",
          data: [],
        },
        {
          type: "vinePlace",
          value: "vine_place",
          data: [],
        },
      ],
    }
  },
  computed: {
    ...mapState("catalogModule", { selectedFilters: "filters" }),
    ...mapState("categoriesModule", ["categoriesProducts", "categoriesFiltered", "subcategories"]),
    getCategoriesIsShown() {
      return !this.$route.query.category
    },
  },
  watch: {
    //DON'T EVEN TOUCH, I'LL FIND YOU MF
    "$route.query": {
      handler(newValue, oldValue) {
        if (newValue?.search) {
          console.log("SEARCH")
          this.handleQueryChange()
        } else if (newValue?.category !== oldValue?.category) {
          console.log("CATEGORY")
          console.log(newValue)
          this.handleQueryChange()
        } else if (newValue?.label !== oldValue?.label) {
          console.log("LABEL")
          this.handleQueryChange()
        } else if (newValue?.view !== oldValue?.view) {
          console.log("VIEW")
          this.handleQueryChange()
        }
      },
    },
    // "$i18n.locale": {
    //   handler() {
    //     this.clearFilters()
    //   },
    // },
    "$route.query.category": {
      handler() {
        this.isShowProductsFilters = true
      },
    },
  },
  created() {
    if (this.$route.query.filters) {
      this.replaceFilters(JSON.parse(this.$route.query.filters))
    }
    if (this.$route.query.category) {
      this.isShowProductsFilters = true
    }
    if (this.$route.query.search) {
      this.$emit("load-products-by-search")
    }
    if (this.categoriesProducts.length === 0) {
      getAllCategoriesFiltered().then((res) => {
        this.categoriesFilters = res
        this.productsIds = []
        this.categoriesFilters.forEach((category) => {
          this.productsIds.push({ id: category.id, data: [] })
          category.subcategories.forEach((subcategory) => {
            subcategory.products.forEach((product) => {
              this.productsIds.find((e) => e.id === category.id).data.push(product.id)
            })
          })
        })
        this.productsIds.forEach((productId) => {
          this.productsList.push({ id: productId.id, data: [] })
          getProductsByIds(productId.data).then((res) => {
            this.productsList.find((e) => e.id === productId.id).data = res
          })
        })
        this.checkCategoryParams()
      })
    } else {
      this.productsList = this.categoriesProducts
      this.categoriesFilters = this.categoriesFiltered
      this.checkCategoryParams()
    }
  },
  beforeDestroy() {
    this.clearFilters()
  },
  methods: {
    ...mapActions("catalogModule", {
      changeFilter: actionTypes.changeFilter,
      replaceFilters: actionTypes.replaceFilters,
      clearFilters: actionTypes.clearFilters,
      clearCategory: actionTypes.clearCategory,
      clearSubcategoryFilters: actionTypes.clearSubcategoryFilters,
      getCategories: actionTypes.getCategories,
      getSubcategories: actionTypes.getSubcategories,
    }),
    ...mapActions("categoriesModule", {
      getCategories: actionTypesCategories.getCategories,
      getCategoriesProducts: actionTypesCategories.getCategoriesProducts,
    }),
    con(filterType, filterItem) {
      console.log(filterType, filterItem)
    },
    async handleQueryChange() {
      this.$emit("clear-pagination")
      await this.clearFilters()
      await this.clearCategory()
      await this.checkCategoryParams()
    },

    checkCategoryParams() {
      // IF WE HAVE CATEGORY ID
      if (this.$route.query?.category) {
        this.category = this.categoriesFilters.find((e) => e.id == this.$route.query?.category)

        if (!this.$route.query?.view) {
          this.subcategories
            .find((e) => e.id == this.category.id)
            .data.forEach((subcategory) => {
              if (!this.selectedFilters.subcategories.includes(subcategory.id)) {
                this.selectFilter("subcategories", subcategory.id)
              }
            })
        } else {
          if (!this.selectedFilters.subcategories.includes(parseInt(this.$route.query?.view))) {
            this.selectFilter("subcategories", parseInt(this.$route.query?.view))
          }
        }
        this.attributes.forEach((atr) => {
          atr.data = []
        })
        setTimeout(() => {
          this.getAttributes()
        }, 500)
        this.getViews()
      }
      // THERE IS NO CATEGORY ID
      else {
        this.category = null
        getAllCategoriesFiltered().then((res) => {
          this.categories = res
          this.getViews()
          this.getAttributes()
        })
      }
    },

    isChecked(filter, filterItem) {
      if (this.selectedFilters[filter?.type].includes(filterItem?.id)) {
        // this.filters.find((f) => f.type === filter?.type).isOpen = true
        return this.selectedFilters[filter?.type].includes(filterItem?.id)
      }
      if (this.selectedFilters[filter?.type].includes(filterItem)) {
        this.filters.find((f) => f.type === filter?.type).isShow = true
        return this.selectedFilters[filter?.type].includes(filterItem)
      }
    },
    getLabel(filterItem) {
      if (filterItem?.attributes) {
        return filterItem?.attributes?.label
          ? filterItem?.attributes?.label
          : filterItem?.attributes?.name
      } else if (filterItem?.label) {
        return filterItem?.label
      } else {
        return filterItem
      }
    },
    getUniqId(filterItem) {
      if (filterItem?.attributes) {
        return filterItem?.attributes?.label
          ? filterItem?.id + filterItem?.attributes?.label
          : filterItem?.id + filterItem?.attributes?.name
      } else if (filterItem?.label) {
        return filterItem?.label
      } else {
        return filterItem
      }
    },
    searchGo() {
      if (this.searchItem.length > 0) {
        this.$router.push({ path: "catalog", query: { search: this.searchItem } })
      } else {
        this.$router.push({ path: "catalog" })
      }
    },
    showFilteredProducts() {
      this.$emit("clear-pagination")
      this.$router.replace({
        path: `${this.$route.path}#catalogHash`,
        query: this.$route.query,
      })
      this.$emit("close-filter")
    },
    resetFilters() {
      window.scrollTo(0, 0, "smooth")
      this.clearFilters()
      this.filters.forEach((filter) => {
        filter.isShow = false
      })
      this.$emit("load-products-by-filter")
    },
    resetCategory() {
      this.clearCategory()
      this.clearFilters()
      // this.categoriesAccordion.forEach((category) => {
      //   category.isOpen = false
      // })
      // this.isShowProductsFilters = false
      this.$emit("load-products-by-filter")
    },
    resetSubcategoryFilters() {
      this.clearSubcategoryFilters()
      this.$emit("load-products-by-filter")
    },

    selectFilter(filterType, filterItem) {
      if (filterItem) {
        this.changeFilter({
          type: filterType,
          filter: filterItem?.id ? filterItem?.id : filterItem,
        })
      }
      this.$router.push({
        query: {
          ...this.$route.query,
          filters: JSON.stringify(this.selectedFilters),
        },
        replace: true,
      })
      // if (this.$route.query.filters) {
      //   this.$emit("load-products-by-filter")
      // }
    },

    checkFilter(filterType, filterItem) {
      // console.log(filterType, filterItem)
      this.selectFilter(filterType, filterItem)
      if (this.$route.query.filters) {
        this.$emit("load-products-by-filter")
      }
    },

    getViews() {
      if (this.category || this.$route?.query?.category) {
        this.filters[0].data = []
        let category = this.categoriesFilters.find((e) => e.id == this.$route?.query?.category)
        category?.subcategories?.forEach((e) => {
          if (e.products.length) {
            this.filters[0].data.push(e)
          }
        })
        if (this.$route.query.subcategories || this.$route.query.filters) {
          if (
            !this.selectedFilters.subcategories.find((e) => e == this.$route.query.subcategories)
          ) {
            this.selectFilter(
              "subcategories",
              this.filters[0].data.find((e) => e.id == this.$route.query.subcategories),
            )
          }
        }
      } else {
        this.filters[0].data = []
      }

      this.filters.forEach((item) => {
        item.isOpen = false
      })
    },

    getAttributes() {
      if (!this.$route.query?.category) {
        this.categories.forEach((category) => {
          category?.subcategories?.forEach((sub) => {
            sub?.products?.forEach((product) => {
              this.attributes.forEach((atr) => {
                atr.data.push(product[atr.value])
              })
            })
          })
        })
      } else {
        this.productsList
          .find((e) => e.id == this.$route.query?.category)
          .data.forEach((e) => {
            this.attributes.forEach((atr) => {
              atr.data.push(e?.attributes[atr.value])
            })
          })
      }
      console.log(
        "lol productsList",
        this.productsList.find((e) => e.id == this.$route.query?.category),
      )
      this.getCategoriesAccordion()

      for (let i = 0; i < this.filters.length; i++) {
        this.attributes.forEach((atr) => {
          if (this.filters[i].type == atr.type) {
            this.filters[i].data = Array.from(
              new Set(atr.data.filter((element) => element != null && element != "")),
            ).sort()
            if (this.$route.query.label) {
              if (
                !this.selectedFilters[this.filters[i].type].find(
                  (e) => e === this.$route.query.label,
                )
              ) {
                this.selectFilter(
                  this.filters[i].type,
                  this.filters[i].data.find((e) => e === this.$route.query.label),
                )
              }
            }
          }
        })
      }

      if (this.$route.query?.search) {
        this.$emit("load-products-by-search")
      } else {
        this.$emit("load-products-by-filter")
      }
    },

    toggleFilterList(filter) {
      const foundFilter = this.filters.find((f) => f?.id === filter?.id)
      if (foundFilter) {
        foundFilter.isOpen = !foundFilter.isOpen
      }
    },

    getCategoryLabel(item) {
      return item?.attributes ? item?.attributes?.label : item?.label
    },

    // accordion
    getCategoriesAccordion() {
      this.categoriesAccordion = []
      this.categoriesFilters.forEach((category) => {
        this.categoriesAccordion.push({
          isOpen: false,
          category: category,
          type: "subcategories",
          subcategories: this.subcategories.find((e) => e.id === category.id)?.data,
        })
      })
      this.categoriesAccordion.forEach((accordion) => {
        if (accordion.category.id == this.$route.query.category) {
          accordion.isOpen = true
        }
      })
    },

    showList(e) {
      e.target.nextElementSibling.classList.toggle("active")
    },

    changeCategory(categoryId) {
      let query = this.$route.query
      delete query.category
      this.$router.push({
        query: {
          category: categoryId,
          ...query,
          filters: JSON.stringify(this.selectedFilters),
        },
      })
      this.getAttributes()
      this.isShowProductsFilters = true
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.loader-mini__container {
  margin: 65px 0;
}
.loader-mini {
  border-top-color: #303132;
}
</style>
